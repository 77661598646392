import throttle from 'lodash/throttle';
import React, { useEffect, useRef, useState } from 'react';

import { ArrowVariants } from '@/components/switchback/Carousel/Arrow';
import Carousel, { Spacing } from '@/components/switchback/Carousel/Carousel';
import { ListingTile } from '@/components/ui/ListingTile';
import { breakpoint } from '@/constants/breakpoint';
import useFavorites from '@/hooks/useFavorites';
import useWindowSize from '@/hooks/useWindowSize';
import {
  trackCarouselInteractionEvent,
  trackListingSelectedEvent,
} from '@/services/analytics/listings';
import { EListingSource, ENavigationType, EProgression } from '@/services/analytics/listings/types';
import { EAlgoSlugVariationKey } from '@/services/experiments';
import { IRentalTile } from '@/utility/mapSearchResultToTile';

import css from './DynamicRentalsCarousel.module.css';

const mapSlideToPage: Record<number, number> = {
  1: 1,
  3: 2,
  6: 3,
};

const renderListingTile = ({
  index,
  rental,
  addFavorite,
  target,
  onClickRental,
  removeFavorite,
}: {
  index: number;
  rental: IRentalTile;
  target?: React.HTMLAttributeAnchorTarget;
  onClickRental: (rental: IRentalTile, index: number) => void;
  addFavorite: (rental: IRentalTile, index: number) => void;
  removeFavorite: (rental: IRentalTile, index: number) => void;
}) => (
  <ListingTile
    key={rental.id}
    rentalTile={rental}
    addFavorite={() => addFavorite(rental, index)}
    removeFavorite={() => removeFavorite(rental, index)}
    target={target}
    onClick={() => onClickRental(rental, index)}
    data-testid="listing-tile"
  />
);

export const RentalsCarousel = ({
  rentalsList,
  eventSource,
  carouselAlgoSlug,
}: {
  rentalsList: IRentalTile[];
  eventSource: EListingSource;
  carouselAlgoSlug: string;
}) => {
  const ref = useRef<HTMLUListElement>(null);
  const [isDesktop, setIsDesktop] = useState(false);
  const [activeSlide, setActiveSlide] = useState(1);
  const windowWidth = useWindowSize()?.width || 0;
  const { addFavorite, removeFavorite } = useFavorites({
    eventSource,
    page: activeSlide,
    carouselAlgoSlug: EAlgoSlugVariationKey.weekender,
  });

  useEffect(() => {
    setIsDesktop(windowWidth >= breakpoint.xl);
  }, [windowWidth]);

  const onClickRental = (rental: IRentalTile, index: number) => {
    trackListingSelectedEvent({
      rental,
      eventSource,
      listingPlacement: index + 1,
      carouselAlgoSlug,
    });
  };

  const handleScroll = throttle(
    () => {
      const scrollContainer = ref.current;
      if (!scrollContainer) return;

      const scrollWidth = scrollContainer.scrollWidth - scrollContainer.clientWidth;
      const scroll = scrollContainer.scrollLeft || 0;
      const startScroll = scroll < 10;
      const endScroll = scroll === scrollWidth;

      const progression = startScroll
        ? EProgression.BACKWARD
        : endScroll
          ? EProgression.FORWARD
          : EProgression.FORWARD;

      trackCarouselInteractionEvent({
        isStay: false,
        progression,
        totalListings: rentalsList.length,
        navigationType: ENavigationType.SWIPE,
      });
    },
    2000,
    { trailing: true, leading: false },
  );

  const handleAfterSlideChange = (index: number) => {
    const mappedSlide = mapSlideToPage[index] || 1;
    const progression = activeSlide > mappedSlide ? EProgression.BACKWARD : EProgression.FORWARD;

    setActiveSlide(mapSlideToPage[index] || 1);

    trackCarouselInteractionEvent({
      isStay: false,
      progression,
      totalListings: rentalsList.length,
      navigationType: ENavigationType.CLICK,
    });
  };

  return (
    <>
      {isDesktop ? (
        <div className={`${css.carouselWrapper}`}>
          <Carousel
            arrows={true}
            componentName="dynamic-rentals-carousel"
            slidesToShow={3}
            slidesToScroll={3}
            hasShadow={false}
            spacing={Spacing.large}
            infinite={false}
            swipeToSlide={true}
            afterSlideChange={handleAfterSlideChange}
            arrowVariant={ArrowVariants.outside}>
            {rentalsList.map((rental, index) =>
              renderListingTile({
                rental,
                index,
                target: '_blank',
                onClickRental,
                addFavorite,
                removeFavorite,
              }),
            )}
          </Carousel>
        </div>
      ) : (
        <ul className={`pt-0 pb-6 ${css.dynamicListings}`} ref={ref} onScroll={handleScroll}>
          {rentalsList.map((rental, index) =>
            renderListingTile({
              rental,
              index,
              onClickRental,
              addFavorite,
              removeFavorite,
            }),
          )}
        </ul>
      )}
    </>
  );
};
