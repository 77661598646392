import { GetStaticProps, NextPage } from 'next';
import React from 'react';
import { useIntl } from 'react-intl';

import { DynamicRentalsCarousel } from '@/components/common/DynamicRentalsCarousel';
import { PromosCarousel } from '@/components/home/PromosCarousel';
import { SignatureDestinations } from '@/components/home/SignatureDestinations';
import HeadMeta from '@/components/static-pages/HeadMeta';
import {
  BannerSection,
  DiscoverTheBest,
  ListYourRV,
  NotesFromOurJournal,
  SeoLinks,
  SocialMedia,
} from '@/graphql/components/home';
import { BecomeA5StarHost } from '@/graphql/components/home/BecomeA5StarHost';
import { EListingSource } from '@/services/analytics/listings/types';
import {
  getCarouselData,
  getHomeBlogPosts,
  getSeoLinks,
  IHomeStaticProps,
  TSeoHomeSection,
} from '@/services/getHomePageData';
import { IBlogPost } from '@/services/types/blog/post.types';
import { IPromoHomepageCarousel } from '@/services/types/promos/promos';
interface Props {
  blogPosts: IBlogPost[];
  seoHomeData: TSeoHomeSection[];
  carouselData: IPromoHomepageCarousel[];
}

const HomePage: NextPage<Props> = ({ blogPosts, seoHomeData, carouselData }) => {
  const intl = useIntl();
  const description = intl.formatMessage({
    defaultMessage:
      'Rent an RV, motorhome, trailer, or campervan from Outdoorsy for your next adventure. Outdoorsy is the #1 most trusted RV rental marketplace in the world',
    id: '/jiUAQ',
  });

  return (
    <>
      <HeadMeta description={description} />
      <div className="bg-canvas-100">
        <BannerSection />
        <SignatureDestinations />
        {carouselData.length > 0 ? <PromosCarousel carouselData={carouselData} /> : <ListYourRV />}
        <DiscoverTheBest />
        <DynamicRentalsCarousel eventSource={EListingSource.HOME_CAROUSEL} />
        <BecomeA5StarHost />
        <NotesFromOurJournal posts={blogPosts} />
        <SocialMedia />
        <SeoLinks seoData={seoHomeData} />
      </div>
    </>
  );
};

export const getStaticProps: GetStaticProps<IHomeStaticProps> = async ({ locale }) => {
  const [seoHomeData, blogPosts] = await Promise.all([getSeoLinks(locale), getHomeBlogPosts()]);
  const carouselData = await getCarouselData();

  return {
    revalidate: 180,
    props: {
      blogPosts,
      isHomePage: true,
      seoHomeData,
      carouselData,
    },
  };
};

export default HomePage;
