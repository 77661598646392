import isNil from 'lodash/isNil';
import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Slider, { ResponsiveObject, Settings as SliderSettings } from 'react-slick';

import useWindowSize from '@/hooks/useWindowSize';
import {
  getAllInteractiveElements,
  getOnFocusableElements,
  hasPressed,
} from '@/utility/accessibility';

import Arrow, { ArrowDirections, ArrowStates, ArrowVariants } from './Arrow';
/* Styles */
import css from './Carousel.module.css';
import { changeStylePerBreakpoint, formatLibProps, TSettings } from './FormatProps';

export enum Spacing {
  small = 'small',
  normal = 'normal',
  large = 'large',
}

export interface IProps extends TSettings {
  lightbox?: boolean;
  children?: React.ReactNode;
  arrowVariant?: ArrowVariants | (null | ArrowVariants)[];
  spacing?: Spacing | Spacing[];
  hasShadow?: boolean | boolean[];
  activeSlide?: number;
  componentName: string;
  headerPagination?: boolean;
  afterSlideChange?: (index: number) => void;
  beforeSlideChange?: (oldIndex: number, newIndex: number) => void;
  responsive?: ResponsiveObject[];
}

const isElementOutViewport = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();

  return (
    rect.bottom < 0 ||
    rect.right < 0 ||
    rect.left > window.innerWidth ||
    rect.top > window.innerHeight
  );
};

const Carousel = React.forwardRef<any, IProps>(function Carousel(
  {
    children,
    lightbox,
    arrowVariant = ArrowVariants.inside,
    spacing,
    hasShadow = false,
    activeSlide,
    headerPagination,
    componentName,
    afterSlideChange,
    beforeSlideChange,
    responsive,
    ...props
  },
  ref,
) {
  const intl = useIntl();
  const [currentSlide, setCurrentSlide] = useState(activeSlide || 0);
  const lastSlide = React.Children.toArray(children).length - 1;
  const firstSlide = 0;
  const carousel: React.RefObject<Slider> = useRef(null);
  const container = useRef<HTMLDivElement>(null);

  const windowSize = useWindowSize();
  const defaultValue = (prop: any) => (Array.isArray(prop) ? prop[0] : prop);

  const [arrowStyle, setArrowStyle] = useState(defaultValue(arrowVariant));
  const [spacingStyle, setSpacingStyle] = useState(defaultValue(spacing));
  const [shadowStyle, setShadowStyle] = useState(defaultValue(hasShadow));

  useEffect(() => {
    changeStylePerBreakpoint(arrowVariant, setArrowStyle, windowSize);
    changeStylePerBreakpoint(spacing, setSpacingStyle, windowSize);
    changeStylePerBreakpoint(hasShadow, setShadowStyle, windowSize);
  }, [arrowVariant, hasShadow, spacing, windowSize]);

  useEffect(() => {
    if (!isNil(activeSlide) && carousel && carousel.current) {
      setCurrentSlide(activeSlide);
      carousel.current.slickGoTo(activeSlide);
    }
  }, [activeSlide]);

  /* These lines below prevent links to be clicked if dragging */

  const [dragging, setDragging] = useState(false);
  const handleOnItemClick = useCallback(e => dragging && e.preventDefault(), [dragging]);

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    setDragging(true);
    e.preventDefault();
  };

  /*----------------------------------------------------*/

  const handleAfterChange = (index: number) => {
    setCurrentSlide(index);
    setDragging(false);
    afterSlideChange && afterSlideChange(index);
  };

  const handleBeforeChange = (oldIndex: number, newIndex: number) => {
    beforeSlideChange && beforeSlideChange(oldIndex, newIndex);
  };

  const customSettings = formatLibProps(props);
  // @ts-expect-error fixable: unchecked index access
  const isInfinite = customSettings.infinite;

  const settings: Partial<SliderSettings> = {
    speed: 200,
    beforeChange: handleBeforeChange,
    afterChange: handleAfterChange,
    nextArrow: arrowStyle && (
      <Arrow
        ref={lightbox ? carousel : null}
        state={currentSlide === lastSlide && !isInfinite ? ArrowStates.hidden : undefined}
        componentName={componentName}
        label={intl.formatMessage({ defaultMessage: 'Go to next slide', id: '/OrWn5' })}
        customClassName={css.arrow || ''}
        variant={arrowStyle}
        hasShadow={shadowStyle}
        direction={ArrowDirections.right}
      />
    ),
    prevArrow: arrowStyle && (
      <Arrow
        ref={lightbox ? carousel : null}
        state={currentSlide === firstSlide && !isInfinite ? ArrowStates.hidden : undefined}
        componentName={componentName}
        label={intl.formatMessage({ defaultMessage: 'Go to previous slide', id: 'QAtVi4' })}
        customClassName={css.arrow || ''}
        variant={arrowStyle}
        hasShadow={shadowStyle}
        direction={ArrowDirections.left}
      />
    ),
    responsive,
    ...customSettings,
  };

  const handleKeyListener = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (carousel && carousel.current) {
      (currentSlide !== lastSlide || isInfinite) &&
        hasPressed(e).RIGHT &&
        carousel.current.slickNext();
      (currentSlide !== firstSlide || isInfinite) &&
        hasPressed(e).LEFT &&
        carousel.current.slickPrev();
    }
  };

  useImperativeHandle(ref, () => ({
    keyBoardNavigation(e: React.KeyboardEvent<HTMLDivElement>) {
      handleKeyListener(e);
    },
    setPosition(index = 0) {
      setCurrentSlide(index);
      carousel.current && carousel.current.slickGoTo(index);
    },
  }));

  useEffect(() => {
    /* Prevent interaction on elements that is not on the current slide */

    const ref = container.current;

    if (!ref) return;

    const { allElements } = getOnFocusableElements({
      container: ref,
      focusableElements: getAllInteractiveElements({
        container: ref,
        restrictions: [],
      }),
    });

    allElements.map(item =>
      isElementOutViewport(item) ? (item.tabIndex = -1) : item.removeAttribute('tabindex'),
    );
  }, [currentSlide]);

  return (
    <div
      data-activeslide={currentSlide}
      data-testid={`${componentName}-carousel`}
      data-arrow={arrowStyle}
      className={css.slider}
      ref={container}
      data-spacing={spacingStyle}>
      {headerPagination && (
        <div className={css.headerPagination}>
          {currentSlide + 1 > 0 ? currentSlide + 1 : 1}/{lastSlide + 1}
        </div>
      )}
      <Slider ref={carousel} className="relative leading-none" {...settings}>
        {React.Children.map(children, child => (
          <div onClickCapture={handleOnItemClick} onDragStart={handleDragStart}>
            {child}
          </div>
        ))}
      </Slider>
    </div>
  );
});

export default Carousel;
